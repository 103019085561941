import { Grid } from '@amzn/awsui-components-react';

import {
  IntroToML,
  ModelTrainingQuota,
  PracticeML,
  PreSeasonRace,
  Resources,
  UpcomingRaceEvents,
  WhatsNew,
} from './common';
import { ScholarshipTracker } from './common/ScholarshipTracker';
import './styles.css';

export const gridDefinition3Layout = [
  { colspan: { default: 12, m: 6 } },
  { colspan: { default: 12, m: 6 } },
  { colspan: { default: 12, m: 6 } },
];

export const gridDefinition2Layout = [{ colspan: { default: 12, m: 6 } }, { colspan: { default: 12, m: 6 } }];

export const StudentHome = () => {
  return (
    <Grid
      data-test-id="studentHomeRoot"
      gridDefinition={[...gridDefinition3Layout, ...gridDefinition2Layout, ...gridDefinition3Layout]}
    >
      <IntroToML />
      <ScholarshipTracker />
      <WhatsNew />
      <Resources />
      <PracticeML />
      <ModelTrainingQuota />
      <PreSeasonRace />
      <UpcomingRaceEvents />
    </Grid>
  );
};
